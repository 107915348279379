@import url(https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Handlee&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.correct {
  color: green;
}

.wrong {
  color: red;
}
body {
  height: 100vh;
  background: #353535;
}

.App {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.hand-writing {
  font-family: "Handlee", cursive;
}
.MuiPaper-root {
  padding: 1em;
}

.float-right {
  float: right;
}

.wrong {
  color: red;
  margin-left: 3px;
}

.right {
  color: greenyellow;
  margin-left: 3px;
}

.answer {
  border-radius: 3px;
  margin: 3px;
  background: #485bc4;
  color: whitesmoke;
}

.MuiLinearProgress-root {
      height: 20px;
    border-radius: 2px;
}


